import React from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../components/Sidebar/Sidebar'

const Cellulose = () => (
    <Layout>
      <Helmet>
          <title>IN-ECO Paper Processing Chemicals</title>
      </Helmet>
      <Header
      styleName='header header-paper'
      title={<span className="text-header-title">Paper processing</span>}
      />
      <div className="columns">
        <div className="column content">
          <div className="content-text">The company JSC "Inžinerinė ekologija" is a specialist in the field of chemical water treatment and is ready to inspect your systems, make analysis of water and prepare an individual water treatment program.</div>
          <ul>
              <li className="content-text">Sedimentation and brightening with the use of synthetic coagulants and flocculants</li>
              <li className="content-text">Biocides, preservatives, surfactants in order to prevent the development of biological processes of rotting and foul odors, improving the quality of pulp and paper making machines continuity</li>
              <li className="content-text">Defoamers, wetting agents and antiscalants</li>
              <li className="content-text">Cleaning concentrates for cleaning of process equipment</li>
              <li className="content-text">Dosing equipment: pumps, automatic dosing installation, including the filing of coagulants and flocculants</li>
          </ul>
        </div>
        <Sidebar
          analysis='Questionnaires'
          boiler='Boiler'
          cooling='Cooling'
          ro='RO'
          broshure='IN-ECO brochure'
          certificates='Certificates'
        />
      </div>
    </Layout>
)

export default Cellulose